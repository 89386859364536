


























































import Vue from 'vue'
import User from '../../../../store/models/User'
import { Response } from '@vuex-orm/plugin-axios'

interface ComponentData {
  columns: Record<string, any>[]
  activeUser: User | null
  deletingUser: boolean
  confirmUserDeletionActive: boolean
}

export default Vue.extend({
  data(): ComponentData {
    return {
      activeUser: null,
      deletingUser: false,
      confirmUserDeletionActive: false,
      columns: [
        {
          label: 'Nom',
          field: 'firstname',
          searchable: true
        },
        {
          label: 'Prénom',
          field: 'lastname',
          searchable: true
        },
        {
          label: 'Email',
          field: 'email',
          searchable: true
        },
        {
          label: 'Téléphone',
          field: 'phone',
          searchable: true
        },
        {
          label: 'Rôle',
          field: 'roleName'
        },
        {
          label: 'Statut',
          field: 'active'
        },
        {
          label: 'Actions',
          field: '_actions',
          align: 'right'
        }
      ]
    }
  },

  computed: {
    items() {
      return User.all()
    }
  },

  created() {
    User.api().fetch()
  },

  methods: {
    onEdit(id: string) {
      this.$router.push({ name: 'users.edit', params: { userId: id } })
    },

    onDeleteUserClick(user: User) {
      this.confirmUserDeletionActive = true
      this.activeUser = user
    },

    onConfirmUserDeletion(this: any) {
      this.deletingUser = true
      User.api()
        .remove(this.activeUser.id)
        .then((response: Response) => {
          this.deletingUser = false
          this.activeUser = null
          this.confirmUserDeletionActive = false
          if (response.response.status === 200) {
            const { action, user } = response.response.data
            switch (action) {
              case 'delete':
                User.delete(user.id)
                this.$notify.success("L'utilisateur a été supprimé avec succès")
                break
              case 'deactivate':
                User.update({ where: user.id, data: user })
                this.$notify.success(
                  "L'utilisateur a été désactivé avec succès"
                )
                break
            }
          }
        })
        .catch((error: any) => {
          this.deletingUser = false
          this.confirmUserDeletionActive = false
          console.log(error) // eslint-disable-line
        })
    }
  }
})
